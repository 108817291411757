import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  ResetPasswordPage,
  ResetYourPasswordMobile
} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>

        {/* Login Route */}
        <Route exact path={Routes.login}>
          <ResetPasswordPage />
        </Route>

        {/* Home Route */}
        <Route exact path={Routes.home}>
          <ResetYourPasswordMobile />
        </Route>

      </Switch>
    </Router>
  );
};

export default RouterApp;
