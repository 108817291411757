import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { PasswordInfo } from "../../components/passwordInfo";
import { Success } from "../../components/success";
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/login_background_1-min.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    // fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  btnCard: {
    position: "sticky",
    bottom: "0",
    padding: "16px",
    backgroundColor: "#fff"
  },
  // main:{
  //   height:"100vh"
  // }
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};

const ResetYourPasswordMobile = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const search = useLocation().search;
  const [isLoader, setIsLoader] = React.useState(false)

  const token = new URLSearchParams(search).get("token");


  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = account?.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)

    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = "New Password is Required";
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = "Password is not stong";
    }

    if (account?.enterPassword === "") {
      isValid = false;
      error.enterPassword = "New Password is Required";
    }
    if (account?.confirmPassword === "") {
      isValid = false;
      error.confirmPassword = "Confirm Password is Required";
    }
    if (account?.error?.enterPassword === "" && account?.enterPassword !== "" && account?.confirmPassword !== "") {
      if (account?.enterPassword !== account?.confirmPassword) {
        isValid = false
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Password Does not match",
        });
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };
  const submit = () => {
    if (isIamValideToLogin() && token) {
      const payload = {
        password: account?.enterPassword,
        token: token
      };
      NetworkCall(
        `${config.api_url}/auth/resetpassword`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Password Updated Successfully",
          });          
          setIsLoader(true)
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "User Not Found",
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <Container maxWidth="sm" style={{ padding: 0, position: "relative", height: "100vh" }}>
        <Box className={classes.main}>
          <Grid container>
            <Grid
              item
              className={classes.content}
              style={{ height: size.height > 734 ? size.height - 94 : 640 }}
            >
              <Grid item xs={12} className={classes.backgroundImage} />
              <Grid style={{ padding: "0px 16px 0px 16px" }}>
                <Box height={"24px"} />
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                  <img
                    src="images/logoOne.png"
                    alt="logo"
                    className={classes.img}
                  />
                  <img
                    src="images/PA Logo.svg"
                    alt="logo"
                    className={classes.img}
                  />
                </Grid>
                <Box height={"16px"} />
                {!isLoader && <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Reset your password
                  </Typography>
                </Grid>}
                <Box height={"24px"} />
                {
                  isLoader ?

                    <Stack alignItems={"center"} >
                      <Success />
                    </Stack>
                    :
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextBox
                            value={account.enterPassword}
                            onChange={(e) => updateState("enterPassword", e.target.value)}
                            label='Enter New Password'
                            placeholder='Enter New Password'
                            isError={account.error.enterPassword.length > 0}
                            errorMessage={account.error.enterPassword}
                            isRequired
                          />

                        </Grid>
                        <Box height={"24px"} />
                        <Grid item xs={12}>
                          <TextBox
                            value={account.confirmPassword}
                            onChange={(e) =>
                              updateState("confirmPassword", e.target.value)
                            }
                            label='Confirm New Password'
                            placeholder='Confirm New Password'
                            isError={account.error.confirmPassword.length > 0}
                            errorMessage={account.error.confirmPassword}
                            isRequired
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PasswordInfo />
                        </Grid>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </Grid>

          </Grid>
          {!isLoader && <Box height={'50px'} />}
        </Box>
        {!isLoader && <Box className={classes.btnCard}>
          <Button
            fullWidth
            variant="contained"
            className={classes.btn}
            onClick={submit}
          >
            <Typography className={classes.buttonTextStyle}>
              Submit
            </Typography>
          </Button>
        </Box>}
      </Container>
    </>
  );
};
export default ResetYourPasswordMobile
