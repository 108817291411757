import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    unordered_list: {
        margin: "10px 22px",
        padding: "0px"
    },
    password_strength: {
        color: theme.typography.color.Tertiary,
        // fontFamily: FontFamilySwitch().semiBold,
        fontSize: theme.palette.labelFontSize,
    }
}));