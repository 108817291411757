import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    paddingBottom: "8px",
    // fontFamily: FontFamilySwitch().semiBold
  },
  text: {},

  textbox: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "10px" : "11px 14px"),
      fontSize: 14,
      // fontFamily: FontFamilySwitch().semiBold
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
}));

export const TextBox = (props) => {
  const classes = useStyles(props);

  const [showPassword, setShowPassword] = React.useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getLabel = (props) => {
    return (
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={classes.Label}
            align="left"
          // gutterBottom
          >
            {props?.label}
          </Typography>
        }
        {props?.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {props?.label?.length > 0 && getLabel(props)}
        <div className={classes.text}>
          <TextField
            onKeyDown={props?.handleKeypress}
            className={classes.textbox}
            type={showPassword ? "text" : "password"}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            style={{
              backgroundColor: props.color ? "white" : "auto",
              borderRadius: "4px",
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autoCapitalize: "none",
            }}
            // InputProps={{
            //   endAdornment: props?.endAdornment,
            //   startAdornment: props?.startAdornment,
            //   style: {
            //     fontSize: props?.fontSize ?? "14px",
            //     padding: props.multiline ? 0 : "none",
            //   },
            // }}
            onKeyPress={props?.onKeyPress}
            disabled={props?.disabled ?? false}
            size="small"
            multiline={props.multiline}
            rows={5}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
TextBox.defaultProps = {
  label: "",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
