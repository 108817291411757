import { Stack,Typography} from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import successLoader from '../../assets/success.json'
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    password:{
        textAlign:"center",
        fontSize:"16px",
        fontFamily:"crayond_bold"
    }
  }));
export const Success = () => {
    const classes = useStyles();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        // here is where we will declare lottie animation
        // "animation" is what we imported before animationData: animation,
        animationData: successLoader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <Stack sx={{ height: "300px", width: "300px", background: "#fff" , alignItems:"center", margin:"0 auto" }}>
            <Lottie options={defaultOptions} height={300} width={300} />
            {/* <Stack height={'16px'}/> */}
            <Typography className={classes.password}>Password Reset Successful</Typography>
        </Stack>
    )
}
