import { Box, Button, Container, Grid, Hidden, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TextBox } from '../../components';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { PasswordInfo } from '../../components/passwordInfo';
import { Success } from '../../components/success';

const useStyles = makeStyles((theme) => ({
    signupform: {
        overflow: "overlay",
        padding: "0px 70px",
        alignContent: "start",
        // height: (size) => size?.height
    },
    image: {
        marginTop: "70px",
        marginBottom: (size) => size?.height > 412.16 ? ((size?.height - 372.16) / 2) : "20px"
    },
    text: {
        fontSize: "24px",
        // fontFamily: ExtraBold,
        color: theme.typography.color.primary
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        opacity: 1,
        padding: "8px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    verificationText: {
        fontSize: "14px",
        // fontFamily: Regular,
        textAlign: "center",
        color: theme.typography.color.primary,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: "15px",
        padding: "20px"

    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        fontSize: "8px",
        color: theme.typography.color.tertiary,
    },
    pa: {
        color: theme.typography.color.secondary,
        // fontFamily: SemiBold,
        fontSize: "10px"
    },
    title: {
        // fontFamily: ExtraBold,
        fontSize: "24px",
        color: "#091B29",
    },
    content: {
        width: "inherit",
    },
    backgroundImage: {
        backgroundColor: "#7EA1FF",
        height: "213px",
        backgroundImage: `url("/images/login_background_1-min.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    img: {
        height: "100%",
        width: "28%",
    },
    root: {
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,25%)"
    }
}))
const InitialState = {
    password: "",
    conform_password: "",
    error: {
        password: "",
        conform_password: "",
    }
}

export const ResetPasswordPage = () => {
    const size = useWindowDimensions()
    const classes = useStyles(size);

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const alert = React.useContext(AlertContext);
    const [value, setValue] = React.useState({ ...InitialState });
    const [isLoader, setIsLoader] = React.useState(false)

    const updateState = (key, email) => {
        let error = value?.error;
        error[key] = "";
        setValue({ ...value, [key]: email, error });
    };

    const isIamValideToLogin = () => {
        let isValid = true;
        let error = value?.error;

        const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        const is_password_strong = is_password_strong_regex.test(value.password)

        //Checking enterPassword
        if (value.password.length === 0) {
            isValid = false;
            error.password = "Password is Required";
        } else if (!Boolean(is_password_strong)) {
            isValid = false;
            error.password = "Password is not stong";
        }

        if (value?.password?.length === 0) {
            isValid = false;
            error.password = "Password is Required";
        }
        if (value?.conform_password?.length === 0) {
            isValid = false;
            error.conform_password = "Confirm Password is Required";
        }
        if (value?.error?.password === "" && value?.password !== "" && value?.conform_password !== "") {
            if (value?.password !== value?.conform_password) {
                isValid = false
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Password Does not match",
                });
            }
        }
        setValue({ ...value, error });
        return isValid;
    };

    const submit = () => {
        if (isIamValideToLogin() && token) {
            const payload = {
                password: value?.password,
                token: token
            };
            NetworkCall(
                `${config.api_url}/auth/resetpassword`,
                NetWorkCallMethods.post,
                payload,
                null,
                false,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Password Updated Successfully",
                    });
                    setIsLoader(true)
                })
                .catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "User Not Found",
                    });
                });
        } else {
            return false;
        }
    };
    return <>
        <Hidden smDown>
            <div className={classes.root}>
                <Grid container className={classes.signupform}>
                    <Box height={"24px"} />
                    <Grid
                        container
                        xs={12}
                        direction="row"
                        justifyContent={"space-between"}
                        alignContent={"center"}
                        mb={10}
                    >
                        <img
                            src="images/logoOne.png"
                            alt="logo"
                            className={classes.img}
                        />
                        <img
                            src="images/PA Logo.svg"
                            alt="logo"
                            className={classes.img}
                        />
                    </Grid>
                    {!isLoader && <Grid item xs={12} marginBottom={"16px"} mt={"16px"}>
                        <Typography className={classes.title}>Reset your password</Typography>
                    </Grid>}
                    {!isLoader && <Grid item xs={12} marginBottom={"16px"}>
                        <TextBox
                            isrequired
                            label='Enter New Password'
                            placeholder='Enter New Password'
                            value={value?.password}
                            isError={value?.error?.password.length > 0}
                            errorMessage={value?.error?.password}
                            onChange={(e) => updateState('password', e.target.value)} />
                    </Grid>}
                    {!isLoader && <Grid item xs={12} marginBottom={"16px"}>
                        <TextBox
                            isrequired
                            label='Confirm New Password'
                            placeholder='Confirm New Password'
                            value={value?.conform_password}
                            isError={value?.error.conform_password?.length > 0}
                            errorMessage={value?.error?.conform_password}
                            onChange={(e) => updateState('conform_password', e.target.value)} />
                    </Grid>}
                    {!isLoader && <Grid item xs={12} marginBottom={"10px"}>
                        <PasswordInfo />
                    </Grid>}
                    {!isLoader && <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            className={classes.btn}
                            onClick={submit}>
                            Submit
                        </Button>
                    </Grid>}
                    {
                        isLoader &&
                        <Grid item xs={12}>
                            <Stack alignItems={"center"} >
                                <Success />
                            </Stack>
                        </Grid>
                    }
                </Grid>
            </div>
        </Hidden>
        <Hidden smUp>
            <div>
                <Container maxWidth="sm" style={{ padding: 0 }}>
                    <Grid container style={{ height: "100vh", backgroundColor: "white" }}>
                        <Grid
                            item
                            className={classes.content}
                            style={{ height: size.height > 580 ? size.height - 82 : 498 }}
                        >
                            <Grid item xs={12} className={classes.backgroundImage} />
                            <Grid style={{ padding: "0px 16px 0px 16px" }}>
                                <Box height={"24px"} />
                                <Grid
                                    container
                                    xs={12}
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignContent={"center"}
                                >
                                    <img
                                        src="images/PA Logo.svg"
                                        alt="logo"
                                        className={classes.img}
                                    />
                                </Grid>
                                <Box height={"16px"} />
                                {isLoader && <Success />}
                                {!isLoader && <Grid item xs={12}>
                                    <Typography className={classes.title}>Reset your password</Typography>
                                </Grid>}
                                {!isLoader && <Grid item xs={12}>
                                    <TextBox
                                        label='Enter New Password'
                                        placeholder='Enter New Password'
                                        value={value?.password}
                                        isError={value?.error?.password.length > 0}
                                        errorMessage={value?.error?.password}
                                        onChange={(e) => updateState('password', e.target.value)} />
                                </Grid>}
                                {!isLoader && <Grid item xs={12}>
                                    <TextBox
                                        label='Enter New Password'
                                        placeholder='Enter New Password'
                                        value={value?.password}
                                        isError={value?.error?.password.length > 0}
                                        errorMessage={value?.error?.password}
                                        onChange={(e) => updateState('password', e.target.value)} />
                                </Grid>}
                            </Grid>
                        </Grid>
                        {!isLoader && <Grid item xs={12} alignSelf={"self-end"} style={{ padding: "16px 16px 25px 16px" }}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.btn}
                                onClick={submit}>
                                Submit
                            </Button>
                        </Grid>}
                    </Grid>
                </Container>
            </div>
        </Hidden>
    </>
}
